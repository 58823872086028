import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MdxLayout.tsx";
import SEO from 'src/components/SEO';
import MdxCard from 'src/components/MdxCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="On Delete" description="Prisma Delete is a plugin built for making delete operations in prisma Prisma. It's a feature that utilizes the
comment's area in prisma.schema to annotate delete side effects on relations. This is a necessary feature as the official
Prisma Migrate Cli has not released a standardized way to resolve Relation onDelete." mdxType="SEO" />
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "introduction"
      }}>{`Introduction`}</h2>
      <p>{`Prisma Delete is a plugin built for making delete operations in prisma `}<a parentName="p" {...{
          "href": "https://prisma.io"
        }}>{`Prisma`}</a>{`. It's a feature that utilizes the
comment's area in `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`prisma.schema`}</code>{` to annotate delete side effects on relations. This is a necessary feature as the official
Prisma Migrate Cli has not released a standardized way to resolve `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`Relation onDelete`}</code>{`.`}</p>
      <p><strong parentName="p">{`CONTENT`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#introduction"
          }}>{`Introduction`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#install"
          }}>{`Install`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#example"
          }}>{`Example`}</a>
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "#schemaprisma"
              }}><code parentName="a" {...{
                  "className": "language-text"
                }}>{`schema.prisma`}</code></a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "#use"
              }}>{`Use`}</a></li>
          </ul>
        </li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#prismadelete-class"
          }}><code parentName="a" {...{
              "className": "language-text"
            }}>{`PrismaDelete`}</code>{` class`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#add-to-context"
          }}>{`Add to Context`}</a></li>
      </ul>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "install"
      }}>{`Install`}</h2>
      <p><a parentName="p" {...{
          "href": "https://npmjs.org/package/@paljs/plugins"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/v/@paljs/plugins.svg",
            "alt": "Version"
          }}></img></a>{`
`}<a parentName="p" {...{
          "href": "https://npmjs.org/package/@paljs/plugins"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/dt/@paljs/plugins.svg",
            "alt": "Downloads/total"
          }}></img></a>{`
`}<a parentName="p" {...{
          "href": "https://paljs.com/"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/l/@paljs/plugins.svg",
            "alt": "License"
          }}></img></a></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}><span parentName="code" {...{
              "className": "token function"
            }}>{`npm`}</span>{` i @paljs/plugins`}</code></pre></div>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "example"
      }}>{`Example`}</h2>
      <p>{`Checkout the example `}<a parentName="p" {...{
          "href": "/cli/create"
        }}><code parentName="a" {...{
            "className": "language-text"
          }}>{`pal create`}</code></a>{` to fast start your next (prisma , nexus , typescript) project`}</p>
      <p>{`In our prisma.schema, we can set the values from the options below to specify the deletion behavior. In case a node with related nodes gets deleted, the deletion behavior determines what should happen to the related nodes. The input values for this argument are defined as an enum with the following possible values:`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`SET_NULL`}</code>{`: Set the related node(s) to `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`null`}</code>{`.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`CASCADE`}</code>{`: Delete the related node(s). Note that is not possible to set both ends of a bidirectional relation to `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`CASCADE`}</code>{`.`}</li>
      </ul>
      <p>{`To add onDelete Relation to any field, just add the annotation one line above the field inside `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`prisma.schema`}</code>{` comment area
`}<code parentName="p" {...{
          "className": "language-text"
        }}>{`/// @onDelete(CASCADE)`}</code>{` or `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`/// @onDelete(SET_NULL)`}</code></p>
      <h3 {...{
        "id": "schemaprisma"
      }}><code parentName="h3" {...{
          "className": "language-text"
        }}>{`schema.prisma`}</code></h3>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "prisma"
      }}><pre parentName="div" {...{
          "className": "language-prisma"
        }}><code parentName="pre" {...{
            "className": "language-prisma"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`datasource`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` db`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  provider `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`"sqlite"`}</span>{`
  url      `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`env`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`"DATABASE_URL"`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`generator`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` client`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  provider `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`"prisma-client-js"`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`model`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` User`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id       `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span>{`       `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`autoincrement`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@id`}</span>{`
  createdAt`}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` DateTime`}</span>{`  `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`now`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  email    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`    `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@unique`}</span>{`
  name     `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span>{`
  password `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`/// @onDelete(CASCADE)`}</span>{`
  posts    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Post`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
  group    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Group`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span>{`    `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@relation`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`fields`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`groupId`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` references`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`id`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  groupId  `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`/// @onDelete(SET_NULL)`}</span>{`
  comments `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Comment`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`model`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` Post`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id       `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span>{`       `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`autoincrement`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@id`}</span>{`
  published`}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Boolean`}</span>{`   `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token boolean"
            }}>{`false`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  title    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`
  author   `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` User`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span>{`     `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@relation`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`fields`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`authorId`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` references`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`id`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  authorId `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`/// @onDelete(CASCADE)`}</span>{`
  comments `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Comment`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
  createdAt`}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` DateTime`}</span>{`  `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`now`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  updatedAt`}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` DateTime`}</span>{`  `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@updatedAt`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`model`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` Comment`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id       `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span>{`      `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`autoincrement`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@id`}</span>{`
  contain  `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`
  post     `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Post`}</span>{`     `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@relation`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`fields`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`postId`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` references`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`id`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  postId   `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span>{`
  author   `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` User`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span>{`    `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@relation`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`fields`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`authorId`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` references`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`id`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  authorId `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span>{`
  createdAt`}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` DateTime`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`now`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  updatedAt`}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` DateTime`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@updatedAt`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`model`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` Group`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id       `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span>{`      `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`autoincrement`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@id`}</span>{`
  name     `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`
  createdAt`}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` DateTime`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`now`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  updatedAt`}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` DateTime`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@updatedAt`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`/// @onDelete(SET_NULL)`}</span>{`
  users    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` User`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <ul>
        <li parentName="ul">{`When a `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`User`}</code>{` record gets deleted, all its related `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`posts`}</code>{` records will be deleted as well and all its related `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`comments`}</code>{` records will be `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`author`}</code>{` `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`null`}</code>{`.`}</li>
        <li parentName="ul">{`When a `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`Post`}</code>{` record gets deleted, it will simply be removed from the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`posts`}</code>{` list on the related `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`User`}</code>{` record and all its related `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`comments`}</code>{` records will be deleted.`}</li>
      </ul>
      <h3 {...{
        "id": "how-it-works"
      }}>{`How it works`}</h3>
      <p>{`When we make a deletion on the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`user`}</code>{` model. The code will go through the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`schema`}</code>{` file that was generated alongside when using Prisma Delete, and check for the annotations
of `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`/// OnDelete(VALUE)`}</code>{` that was set on the schema.`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` PrismaDelete `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/plugins'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`field`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'deleteOneUser'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'User'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  nullable`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  args`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    where`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`arg`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'UserWhereUniqueInput'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      nullable`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`false`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`resolve`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`async`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`_`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` where `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` select `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` prismaDelete `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`new`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`PrismaDelete`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`await`}</span>{` prismaDelete`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`onDelete`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` model`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'User'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` where `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`user`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token keyword"
            }}>{`delete`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      where`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span>{`select`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// normal resolver`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` resolvers `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  Query`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`deleteOneUser`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`async`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` where `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` prisma `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` prismaDelete `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`new`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`PrismaDelete`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`await`}</span>{` prismaDelete`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`onDelete`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` model`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'User'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` where `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`user`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token keyword"
            }}>{`delete`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
        where`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
        `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span>{`select`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "prismadelete-class"
      }}><code parentName="h2" {...{
          "className": "language-text"
        }}>{`PrismaDelete`}</code>{` class`}</h2>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`prisma`}</code>{` prisma client class`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`options`}</code>{` `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`{ dmmf?: DMMF.Document }`}</code>{` you can send custom options for now we just have one option to pass custom `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`dmmf`}</code>{` object if you have a custom generated client path.`}</li>
      </ul>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`prismaDelete.onDelete`}</code>{` accept object`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`model`}</code>{` model name that was defined in `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`schema.prisma`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`where`}</code>{` query object to retrive the result `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`{ id: 1}`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`deleteParent`}</code>{` A flag to determine whether the model should be deleted and returned when its defined as `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`true`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`returnFields`}</code>{` you can select what you need to return from parent record `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`{id: true, name: true}`}</code></li>
      </ul>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`await`}</span>{` prismaDelete`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`onDelete`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` model`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'User'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` where`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` deleteParent`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h2 {...{
        "id": "add-to-context"
      }}>{`Add to Context`}</h2>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` PrismaClient`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` PrismaClientOptions `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@prisma/client'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` PrismaDelete`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` onDeleteArgs `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/plugins'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`class`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`Prisma`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`extends`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`PrismaClient`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`constructor`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`options`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` PrismaClientOptions`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`super`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`options`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`async`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`onDelete`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`args`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` onDeleteArgs`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` prismaDelete `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`new`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`PrismaDelete`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token keyword"
            }}>{`this`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`await`}</span>{` prismaDelete`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`onDelete`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`args`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` prisma `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`new`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`Prisma`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`interface`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`Context`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  prisma`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`function`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`createContext`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Context `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <p>{`The above code below is how it should be used in resolvers. This part of code could also be auto generated
from Pal.js's server models.`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`resolve`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`async`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`_`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` where `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` prisma `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`await`}</span>{` prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`onDelete`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` model`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'User'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` where`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` deleteParent`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
    </MdxCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      